import logo from '@images/logo.svg';
import styled, { createGlobalStyle } from 'styled-components';
import { Header } from '@/components/new/Header';
import { BaseStyle } from '@/components/ui/BaseStyle';
import { Image } from '@/components/ui/Image';
import { HelpMenu } from '../Header/HelpMenu';
import { NewsMenu } from '../Header/NewsMenu';
import { HowToUseStorylaneModal } from '../HowToUseStorylaneModal';

const headerHeight = '40px';

const GlobalStyle = createGlobalStyle`
${BaseStyle}
html {
  background-color: #EFF1F4;
}
`;

const Wrapper = styled.div`
  height: 100vh;
  padding-top: ${headerHeight};
`;

const Anchor = styled.a`
  display: inline-flex;
  text-decoration: none;
`;

export type StaticPageLayoutProps = {
  children: React.ReactNode;
};
export const StaticPageLayout = ({
  children,
}: StaticPageLayoutProps): JSX.Element => (
  <>
    <GlobalStyle />
    <Wrapper>
      <Header.Root>
        <Header.Name>
          <Anchor href="/">
            <Image
              src={logo}
              width={238}
              height={20}
              alt="Money Forward Pay for Business"
            />
          </Anchor>
        </Header.Name>
        <Header.Item>
          <NewsMenu />
          <HelpMenu />
        </Header.Item>
      </Header.Root>
      <HowToUseStorylaneModal />
      <main>{children}</main>
    </Wrapper>
  </>
);
