import { Login } from '@/components/screen';
import { StaticPageLayout } from '@/components/screen/layouts/StaticPageLayout';
import { PageWithLayout } from '@/lib/page';

const LoginPage: PageWithLayout = () => <Login />;

LoginPage.displayName = 'LoginPage';
LoginPage.getLayout = (page) => <StaticPageLayout>{page}</StaticPageLayout>;

export default LoginPage;
